<template>
  <v-sheet
    class="casts-in-date"
    :max-height="$attrs.height"
    color="transparent"
  >
    <v-row v-if="!castsIn.length">
      <v-col cols="12">
        <v-card flat class="transparent">
          <v-card-text>
            出勤データがありません。
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row no-gutters
      v-if="castsIn.length"
    >
      <draggable
        class="draggable d-flex flex-wrap"
        v-model="castsIn"
        animation="100"
        :disabled="$attrs.disabledSort"
        @sort="onSort"
      >
        <v-col cols="4" md="3" xl="2"
          class="pa-1"
          v-for="(cast, index) in castsIn"
          :key="cast.cast_id"
          :class="{ dummy: cast.is_dummy }"
        >
          <v-card class="d-flex flex-column"
            color="primary"
          >
            <v-badge
              :color="cast.is_dummy ? 'grey' : 'accent'"
              :content="index + 1"
            >
              <v-img
                class="rounded-t"
                v-if="cast.image_url"
                :src="cast.image_url"
                cover
                height="120"
              ></v-img>
            </v-badge>
            <p
              class="align-self-center text-caption"
            >
              {{ cast.cast_name }}
            </p>
            <p class="align-self-center text-caption">
              {{ getHHmm(cast.start_at) }}~{{ getHHmm(cast.end_at) }}
            </p>
          </v-card>
        </v-col>
      </draggable>
    </v-row>

    <!-- ローダー -->
    <v-progress-linear
    :active="loading"
    :indeterminate="loading"
    absolute top
    color="primary"
    ></v-progress-linear>

    <!-- スナックバー -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

  </v-sheet>
</template>

<script>
import moment from 'moment'
import draggable from 'vuedraggable'

import $literals from '@/literals.js'
import { ApiTool, CheckTokenError } from '@/module.js'

export default {
  inheritAttrs: false,

  components: {
    draggable: draggable,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    },
    castsData: {
      type: Array,
      required: true
    },
    initDate: {
      type: String
    }
  },

  //***************************************************
  //データ
  //***************************************************
  data() {
    return {
      casts: [],
      castsIn: [],
      loading: false,
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
    }
  },

  //***************************************************
  //算出
  //***************************************************
  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
    getHHmm() {
      return date => moment(date).format('HH:mm')
    },
  },

  //***************************************************
  //ライフサイクル
  //***************************************************
  created() {
    this.adminApi.setToken(this.serverToken)

    this.getData()
    .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
  },

  //***************************************************
  //メソッド
  //***************************************************
  methods: {
    onSort() {
      this.$emit('sorted')
    },

    async getData() {
      this.loading = true

      await this.setCasts()

      await Promise.all([
        this.setShiftsOfDay(this.initDate),
      ])

      this.loading = false
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //**************************************************
    //キャストデータをセット
    //**************************************************
    setCasts() {
      if (this.castsData.length > 0) {
        this.casts = [...this.castsData]
      } else {
        return this.adminApi.getReqWithAuth('cast/').then( results => {
          if (!results || !results.length) return

          this.casts = [...results]
        })
      }
    },

    //**************************************************
    //シフトデータをセット
    //**************************************************
    setShiftsOfDay(date) {
      if (!date) date = new Date()

      return this.adminApi.getReqWithAuth('shift/date/' + moment(date).format('YYYY-MM-DD')).then( results => {
        this.castsIn = []

        results.map(shift => {
          this.casts.map( cast => {
            if (cast.cast_id === shift.cast_id) shift.image_url = cast.image_url
          })

          if (shift.start_at !== shift.end_at) this.castsIn.push(shift)
        })

        this.$emit('loadedCastsInDate', {count: this.castsIn.length})
      })
    },

    //**************************************************
    //並び順更新
    //**************************************************
    updateOrder() {
      this.loading = true

      const updateData = []
      this.castsIn.map( (shift, i) => {
        updateData.push({
          id: shift.shift_id,
          cast_id: shift.cast_id,
          display_order: this.castsIn.length - i
        })
      })

      this.adminApi.apiReqWithData('PUT',  'shift/update/order/', JSON.stringify(updateData)).then(() => {
        this.snackbar = {...{color:'info', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => {
        this.loading = false
        this.$emit('updatedOrder')
      })
    }
  }
}
</script>

<style scoped>
.draggable {
  width: 100%;
}
>>> .v-badge__badge {
  position: initial;
}
.casts-in-date {
  overflow-y: scroll;
}
.text-caption {
  word-break: break-word;
}
</style>
